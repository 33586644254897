@import './../../../assets/styles/app.scss';
@import './../../../assets/styles/partials/_modal.scss';

.ticket-tchat-header {
    display: flex;
    font-size: 1.2rem;
    padding: 1rem 0;
    font-family: "Goldplay Medium";
    overflow-x: auto;
    scrollbar-color: $primary-color $bg-medium-transparent;

    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: $bg-medium-transparent;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary-color;
        border-radius: 8px;
    }

    .priority-label {
        padding: 0.2rem 1rem;
        border-radius: 30px;
        margin-right: 1rem;
        text-transform: capitalize;
    }

    p {
        padding: 0 3rem;
        white-space: nowrap;

        @media screen and (max-width: $lg-screen) {
            padding: 0 2rem;
        }

        @media screen and (max-width: $md-screen) {
            padding: 0 1.5rem;
            font-size: 1rem;
        }

        @media screen and (max-width: $sm-screen) {
            padding: 0 1rem;
        }
    }

}

.window-chat {
    background-color: $bg-medium-transparent;
    border-radius: $border-small;
    padding: 2rem 2rem 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media screen and (max-width: $sm-screen) {
        padding: 1rem;
    }

    .echanges-container {
        padding-right: 1rem;
        max-height: 28rem;
        overflow-y: scroll;
        scrollbar-color: grey $bg-medium-transparent;
        overflow-x: hidden;
        margin-bottom: 1rem;
        position: relative;

        &::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: $bg-medium-transparent;
            border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: grey;
            border-radius: 8px;
        }


        .message-detail {
            margin-bottom: 2rem;

            @media screen and (max-width: $sm-screen) {
                margin-bottom: 0.5rem;
            }

            .top {
                display: flex;
                align-items: flex-end;

                @media screen and (max-width: $sm-screen) {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }

                .avatar-container {
                    width: 4rem;
                    min-width: 4rem;

                    @media screen and (max-width: $sm-screen) {
                        margin-top: 10px;
                        width: 2rem;
                        min-width: 2rem;
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .message-content {
                    line-height: 1.3;
                    max-width: 60rem;
                    background-color: rgb(56, 56, 56);
                    padding: 1rem;
                    border-radius: 12px;
                    font-size: 1.1rem;
                    font-family: "Goldplay";
                    margin-right: 1rem;
                    margin-left: 1rem;
                    white-space: pre-wrap;

                    @media screen and (max-width: $sm-screen) {
                        font-size: 1rem;
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }

            .date {
                margin-top: 8px;
                font-size: 0.9rem;

                @media screen and (max-width: $sm-screen) {
                    transform: translate(2.5rem, -2rem);
                }
            }

            .upload-link {
                width: fit-content;
                display: flex;
                align-items: center;
                font-family: "Goldplay SemiBold";
                margin-top: 5px;
                font-size: 0.9rem;

                @media screen and (max-width: $sm-screen) {
                    transform: translate(0, -1.8rem);
                    font-size: 0.8rem
                }

                .material-icons {
                    transform: rotate(-45deg);
                    margin-right: 2px;
                }
            }
        }

        .message-right {
            .top {
                justify-content: flex-start;
                flex-direction: row-reverse;

                @media screen and (max-width: $sm-screen) {
                    flex-direction: column-reverse;
                    align-items: flex-end;
                }
            }

            .date {
                text-align: right;

                @media screen and (max-width: $sm-screen) {
                    transform: translate(-2.5rem, -2rem);
                }
            }

            .upload-link {
                margin-left: auto;
                justify-content: flex-end;

                @media screen and (max-width: $sm-screen) {
                    transform: translate(-2rem, -2rem)
                }
            }
        }


    }

    .send-form {
        position: relative;

        .new-messages {
            background-color: $primary-color;
            color: white;
            position: absolute;
            top: -2rem;
            left: 50%;
            transform: translate(-50%);
            display: flex;
            align-items: center;
            padding: 0.5em 1rem;
            border-radius: 15px;
            z-index: 1000;
            font-size: 0.9rem;
            cursor: pointer;
            transition: 0.6s;

            .material-icons {
                font-size: 1rem;
                margin-left: 10px;
                animation: bounce 0.7s ease infinite;
            }


            @keyframes bounce {
                from {
                    transform: translateY(-3px);
                }

                50% {
                    transform: translateY(3px);
                }

                to {
                    transform: translateY(-3px);
                }
            }
        }

        .text-input {
            position: relative;
            margin-bottom: 1rem;

            textarea {
                width: 100%;
                outline: none;
                border-radius: 10px;
                border: none;
                padding: 1rem;
                padding-right: 4rem;
                background-color: $bg-medium-transparent;
                color: $text-light;
                font-family: "Goldplay";
                font-size: 1.1rem;
                white-space: pre-line;

                @media screen and (max-width: $sm-screen) {
                    padding-right: 3rem;
                }
            }
        }

        .send-btn {
            position: absolute;
            bottom: 0.5rem;
            right: 1rem;
            background-color: transparent;
            color: $primary-color;
            padding: 1rem;
            border: none;
            cursor: pointer;
            transform: rotate(-45deg);
            transition: 0.3s;

            svg {
                height: 2rem;
                width: 2rem;
            }

            &:hover {
                opacity: 0.5;
            }

            @media screen and (max-width: $lg-screen) {
                right: 0;
            }

            .material-icons {
                font-size: 2rem;

                @media screen and (max-width: $sm-screen) {
                    font-size: 1.5rem;
                }
            }
        }

        [id^="echange_uploads"] {
            margin-top: 5px;
            cursor: pointer;
        }

        .uploads-container{
            margin-top: 1rem;
        }

    }
}