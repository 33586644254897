.table-container, .maintenances-container, .settings-display, .settings-list  {
    overflow-x: auto;
    scrollbar-color: $primary-color $bg-medium-transparent;

    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: $bg-medium-transparent;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary-color;
        border-radius: 8px;
    }
}

.list-container {
    // margin-left: 1rem;
    border-collapse: separate;
    border-spacing: 0 1rem;

    .list-container--header {
        font-family: "Goldplay Medium";
        font-size: 1.3rem;
        text-align: left;
        white-space: nowrap;

        @media screen and (max-width: $md-screen) {
            font-size: 1rem;
        }

        th+th {
            padding-left: 2rem;

            @media screen and (max-width: $md-screen) {
                padding-left: 1.5rem;
            }
        }
    }

    .list-item {
        cursor: pointer;
        
        &:hover{
            td {
                opacity: 1 !important;
            }
        }

        @media screen and (max-width: $sm-screen) {
            .author-infos {
                padding: 1rem 0.5rem 1rem !important;
            }
        }

        &--padding{
            td{
                padding-top: 1rem !important;
                padding-bottom: 1rem !important;
            }

            td:first-child{
                padding-left: 1rem;
            }
        }
        .author-infos {
            display: flex;
            align-items: center;
        }

        .avatar-container {
            width: 2.6rem;
            margin-right: 1rem;
            margin-left: 1rem;

            &:not(.avatar-container--user) {
                @media screen and (max-width: $sm-screen) {
                    display: none;
                }
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .priority-label {
            border-radius: 30px;
            padding: 0.2rem 0.6rem;
            text-transform: uppercase;
            font-family: 'Goldplay Semibold';
            font-size: 1rem;
            margin-right: 5px;
        }
    }

    .list-item,
    .list-container--header {
        &:hover {
            td {
                background-color: rgb(56, 56, 56);
            }
        }

        td,
        .report-link,
        .invoice-link {
            font-size: 1.1rem;
            white-space: nowrap;
            vertical-align: middle;
            display: table-cell;
            background-color: $bg-medium-transparent;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            transition: 0.3s;

            @media screen and (max-width: $md-screen) {
                font-size: 1rem;
            }

            &:first-child {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }

            &:last-child {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                padding-right: 4rem;
            }

            +td {
                padding-left: 2rem;
                padding-right: 2rem;

                @media screen and (max-width: $md-screen) {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;

                }
            }
        }
    }
}


.create-item-btn {
    margin-top: 1rem;
    margin-left: auto;
}

// Spécifique à la liste des tickets + factures
.list-tickets-container {

    .list-item-client,
    .invoice-item {
        td {
            padding: 1.5rem 1rem;
        }
    }
}

.no-item-found{
    margin-top: 2rem;
    font-size: 1.2rem;
}