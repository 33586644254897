body {
    color: $text-light;
    font-family: Goldplay;
    background-color: $bg-medium;
}

a {
    color: $text-light;
}

p {
    line-height: 1.2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $text-light;
    -webkit-box-shadow: 0 0 0px 1000px $bg-medium-transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

h1 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 2rem;

    @media screen and (max-width: $sm-screen) {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
        font-family: "Goldplay Medium";
    }

}

.page-title {
    font-size: 2.2rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-family: "Goldplay Medium";
    margin-left: 1rem;

    @media screen and (max-width: $md-screen) {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
}

.btn {
    padding: $btn-padding;
    font-size: 1.2rem;
    border-radius: $btn-border;
    border: none;
    width: max-content;
    color: $text-light;
    transition: 0.3s;
    cursor: pointer;

    @media screen and (max-width: $md-screen) {
        font-size: 1.1rem;
    }

    .material-icons {
        margin-right: 8px;
        display: flex;
    }

    display: flex;
    align-items: center;

}

.btn-delete, .btn-issue, .btn-alert{
    font-size: 1.1rem;
    background-color: rgb(170, 0, 0);
    &:hover{
        background-color :rgb(145, 0, 0);
    }
}

.btn:disabled {
    opacity: 0.7;
    pointer-events: none;
}

.btn-container--center {
    margin: 2rem auto;
    width: max-content;
    cursor: pointer
}

.btn-primary {
    background-color: $primary-color;

    &:hover {
        background-color: #1e8886;
    }
}


.btn-container{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: max-content;
    .btn{
        margin-left: 1.5rem;
        @media screen and (max-width: $md-screen) {
            margin-top: 0.5rem !important;
            margin-bottom: 1rem !important;
            margin-left: 1rem !important;
        }
    }
}

.btn-container--left{
    justify-content: flex-start;
    max-width: 100%;
    .btn{
        margin-left: 0rem ;
        margin-right: 1rem ;
    }
}

.alert,
.form-errors li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1.4rem;

    svg {
        margin-right: 5px;
    }
}


.loading-container {
    display: flex;
    flex-direction: column;
    text-align: center;

    p {
        margin-top: 1rem;
        font-size: 1.5rem;
    }

    .logo-container {
        width: 8rem;
        margin: auto;

        img {
            width: 100%;
            animation: rotate 10s linear infinite;

        }

        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }

    }
}

.app-container {
    display: flex;
    justify-content: stretch;
    min-height: 100vh;
    background-color: $bg-dark;

    &.loading {
        justify-content: center;
        align-items: center;
    }

    .app-container--inner {
        background-image: $bg-gradient-dark;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        padding: 8rem 2rem 0;
        padding-left: 20rem;
        position: relative;
        transition: 0.5s;
        width: 100%;


        @media screen and (max-width: $md-screen) {
            padding-left: 2rem;
            padding-top: 7rem;
        }


        &.profile-display {
            padding-top: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .app-content {
            width: 100%;
            margin-bottom: 1rem;
        }

        .welcome-msg {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            right: 5rem;
            position: absolute;
            top: 2.5rem;

            @media screen and (max-width: $md-screen) {
                right: 2rem;
                top: 2rem;

                p {
                    display: none;
                }
            }

            p {
                font-size: 1.2rem;
                margin-right: 12px;

                .name {
                    text-transform: capitalize;
                    font-family: "Goldplay SemiBold";
                }
            }

            .avatar-container {
                img {
                    width: 4rem;
                    object-fit: cover;
                }
            }
        }
    }
}

.empty-message {
    margin-left: 1rem;
    font-size: 1.5rem;
}






textarea {
    font-family: "Goldplay";
}

.go-back {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-family: "Goldplay Medium";
    transition: 0.3s;
    color: $primary-color;
    text-transform: uppercase;
    cursor: pointer;

    span {
        font-size: 1.4rem;
    }
}

.upload-link {
    transition: 0.3s;
    &:hover {
        opacity: 0.6;
    }

    svg {
        transform: rotate(30deg);
    }
}


.header-with-cta{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;

    h1{
        margin-bottom: 0rem;
    }

    .btn{
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-top: 0;

        svg{
            height: 1rem;
            width: 1rem;
            margin-right: 3px;
        }
    }

    @media screen and (max-width: $sm-screen) {
        h1{
            margin-top: 0rem;
        }
    }
}

