@import './../../assets/styles/abstract/variables.scss';

.maintenances-header-select {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    select {
        // appearance: none;
        background: rgba(58, 58, 58, 0.5);
        min-width: 13rem;
        color: white;
        padding: 1rem 1rem;
        border: none;
        font-size: 1.1rem;
        outline: none;
        cursor: pointer;
        border-radius: 10px;
        text-transform: capitalize
    }
}

.add-contract-form {
    .date-input {
        width: min-content;
    }
}


.maintenances-container {
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;

    .maintenance-list {
        display: flex;
        gap: 1rem;

    }

    .maintenance-item {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border: 1px solid rgb(88, 88, 88);
        border-radius: 15px;
        text-align: center;
        min-width: 10rem;
        width: 14rem;
        height: 100%;

        &.is-done {
            border: 1px solid $primary-color;
        }

        hr {
            width: 50%;
            border: 0;
            border-top: 1px solid rgb(46, 46, 46);
            margin: 1.2rem auto;
        }

        .checkbox {
            display: flex;
            justify-content: center;
            align-items: baseline;

            input {
                width: 1.5rem;
                margin: 0;
            }
        }

        .label {
            margin-bottom: 0.5rem;
            font-family: "Goldplay Medium";
        }

        .date {
            font-family: "Goldplay Semibold";

            .month {
                font-size: 1.8rem;
                text-transform: uppercase;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;

            &:hover {
                color: $primary-color;
            }

            svg {
                transform: rotate(45deg);
            }
        }

        button {
            font-size: 1rem;
            padding: 0.6rem 1rem;
            margin: 2rem auto 0;

            &.btn-alert{
                margin: 1rem auto 0;
            }
        }
    }
}


.btn-add-maintenance {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 10rem;
    min-height: 25rem;
    border: 1px solid rgb(52, 52, 52);
    border-radius: 15px;
    min-width: 6rem;

    &:hover {
        svg {
            background-color: $primary-color;
        }
    }

    svg {
        width: 3.6rem;
        height: 3.6rem;
        background-color: $bg-medium;
        border-radius: 100%;
        padding: 0.8rem;
        transition: 0.3s;
    }

}

.add-maintenance-form,
.edit-maintenance-form {
    border: 1px solid rgb(52, 52, 52);
    border-radius: 15px;
    padding: 1rem;

    select {
        background: rgba(58, 58, 58, 0.5);
        min-width: 10rem;
        color: white;
        padding: 0.8rem 1rem;
        border: none;
        font-size: 1rem;
        outline: none;
        cursor: pointer;
        border-radius: 10px;
        margin-bottom: 1rem;
    }

    .file-input {
        label {
            margin-bottom: 0.5rem;
            margin-top: 0.4rem;
            display: inline-block;
            font-family: "Goldplay Medium";
        }

        input {
            border-radius: unset;
            background-color: transparent;
            padding: 0;
            font-size: 1rem;
        }

    }

    .upload-info {
        font-size: 0.9rem;
    }

    .time-spent {
        margin: 0.5rem 0;
        display: inline-block;

        input {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            border-radius: 6px;
        }
    }

    button {
        font-size: 1rem;
        padding: 0.6rem 1rem;
    }
}

.edit-maintenance-form {
    hr {
        margin: 0.5rem auto !important;
    }
}