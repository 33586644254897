@font-face {
    font-family: 'Goldplay';
    src:  url('../../fonts/Latinotype-Goldplay-Regular.woff') format('woff'),
          url('../../fonts/Latinotype-Goldplay-Regular.otf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Goldplay Bold';
    src: url('../../fonts/Latinotype-Goldplay-Bold.otf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Goldplay Thin';
    src:  url('../../fonts/Latinotype-Goldplay-Thin.woff') format('woff'),
          url('../../fonts/Latinotype-Goldplay-Thin.otf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Goldplay Medium';
    src:  url('../../fonts/Latinotype-Goldplay-Medium.woff') format('woff'),
    url('../../fonts/Latinotype-Goldplay-Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Goldplay Semibold';
    src:  url('../../fonts/Latinotype-Goldplay-SemiBold.woff') format('woff'),
    url('../../fonts/Latinotype-Goldplay-SemiBold.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

