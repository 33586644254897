.modal {
    background: #242424;
    border-radius: 15px;
    border: 1px solid $text-grey;
    position: fixed;
    padding: 2rem;
    top: 20%;
    left: 50%;
    z-index: 10;
    opacity: 100%;

    @media screen and (max-width:$md-screen) {
        transform: translateX(-50%);
    }

    .close-icon {
        text-align: right;
        cursor: pointer;
        margin-bottom: 1rem;

        svg {
            width: 2rem;
            height: 2rem;
        }

    }

    .btn {
        margin-top: 1rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .form-input {
        display: flex;

        select {
            margin-left: 10rem;

            @media screen and (max-width:$sm-screen) {
                margin-left: 0;
            }
        }
    }
}

.modal-delete {
    lottie-player {
        width: 12rem;
        margin: auto;
    }

    p {
        text-align: center;
        font-size: 1.1rem;
        margin-top: 1rem;
    }

}

.modal-edit {
    width: 95%;
    max-width: 30rem;

    @media screen and (min-width:$md-screen) {
        left: 38%;
    }
}

.alert-success {
    position: fixed;
    background: $success;
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 15px;
    left: 50%;
    top: 20%;
    font-family: 'Goldplay Medium';
    transform: translateX(-50%);
    z-index: 50;
    transition: 0.6s;
    text-align: center;
    display: flex;
    align-items: center;

    lottie-player {
        width: 5em;
        margin-right: 1rem;
    }


    @media screen and (max-width:$md-screen) {
        flex-direction: column;
        font-size: 1.2rem;
        width: 80%;
        max-width: 25rem;
    }
}

.modal-disappear {
    opacity: 0;
    top: 0;
}

.modal-add {

    @media screen and (max-width: $md-screen) {
        width: 90%;
        max-width: 35rem;
    }

    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .checkbox {
        margin-top: 2rem;
        display: flex;

        label {
            display: inline-block;
        }

        input {
            width: 1rem;
        }
    }

    input[type=color] {
        width: 5rem;
        height: 2.5rem;
        width: 6rem;
        padding: 0;
        border-radius: 0;
        background: none;
    }

}