$primary-color: #2eb5b2;
$text-light : #fff;
$text-grey : #9a999a;
$success : #247924;
$alert : #d50000;
$bg-dark: hsl(0, 0%, 7%);
$bg-medium: #242424;
$bg-medium-transparent: rgba(58, 58, 58, 0.5);
$bg-gradient-dark: linear-gradient(156deg, rgb(39, 39, 39)  0%, rgb(27, 27, 27) 44%, rgba(19,19,19,1) 100%);

$btn-padding: 1rem 1.5rem;
$btn-padding-sm: 0.5rem 1rem;
$btn-border: 30px;
$border-small: 20px;

$sm-screen: 31rem; //500px
$md-screen: 50rem; //800px
$lg-screen: 75rem; //1200px