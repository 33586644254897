@import './../../../assets/styles/app.scss';



.invoice-download {
   border: 1px solid $text-grey;
   border-radius: $border-small;
   width: fit-content;
   transition: 0.3s;

   &:hover {
      background-color: $bg-medium-transparent;
   }

   a {
      display: flex;
      align-items: center;
      padding: 1rem;

      svg {
         margin-right: 0.4rem;
      }

      span {
         margin-right: 0.5rem;
      }
   }

}

.invoice-files-container {
   display: flex;
   gap: 1rem;
   flex-wrap: wrap;
   // @media screen and (max-width: $sm-screen) {
   //    display: block;
   // }
   margin: 2rem 0 1rem;

   .invoice-file-info {
      border: 1px solid grey;
      padding: 1rem 1rem;
      border-radius: 15px;
      width: fit-content;

      +.invoice-file-info {
         margin-left: 1rem;

         @media screen and (max-width: $sm-screen) {
            margin-left: 0;
         }
      }

      .title {
         display: flex;
         align-items: center;
         margin-bottom: 0.5rem;

         span {
            margin-right: 0.4rem;
         }
      }

   }
}

.invoice-files-container {
   .invoice-file-info {
      margin: 1rem 0 1rem;
      border: 1px solid grey;
      padding: 1rem 1rem;
      border-radius: 15px;
      width: fit-content;
      max-width: 40rem;

      .title {
         display: flex;
         align-items: center;
         margin-bottom: 0.5rem;

         svg {
            margin-right: 0.4rem;
         }
      }

   }
}

.invoice-details {
   padding: 1.5rem 0.8rem;
   border-radius: $border-small;
   border: 1px solid #363636;
   margin-bottom: 1rem;
   width: max-content;

   p {
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      line-height: 1.4;
   }
}