.search-container{
    @media screen and (max-width: $sm-screen) {
        margin-top: 1.5rem;
    }
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .search-container-inputs{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    select, input, .search-btn{
        height: 2rem;
        background-color: $bg-medium;
        color: $text-light;
        border: 1px solid $bg-medium-transparent;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }

    input{ 
        padding: 1rem 1em;
        height: 2rem;
        background-color: #242424;
        color: #fff;
        border: 1px solid rgba(58, 58, 58, 0.5);
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: auto;
        border-radius: 0;
        font-size: 0.8rem;
    }

    select, .search-btn{
        padding: 0 1rem;
    }

    .search-btn{
        color: $primary-color;
    }
    
}

.search-btn{
    height: 2rem;
    background-color: $bg-medium;
    border: 1px solid $bg-medium-transparent;
    color: $primary-color;
    border-radius: $border-small;
    cursor: pointer;
    padding: 0 1rem;


    .btn-content{
        display: flex;
        align-items: center;
    }
}


.search-btn--display {
    padding: 0.5rem 1rem;
    height: auto;
    font-size: 1rem;
    margin-bottom: 1rem;
    .material-icons{
        margin-right: 0.5rem;
    }
}
