@import './../../assets/styles/abstract/variables';

.settings-container {
    display: flex;
    gap: 1rem;

    @media screen and (max-width: $lg-screen) {
        flex-direction: column;
    }

    .settings-list {
        background-color: $bg-medium-transparent;
        width: 15rem;
        min-width: 12rem;
        padding: 3rem 2rem;
        border-radius: 5px;
        min-height: 50vh;

        .icon {
            margin: 0rem auto 2rem;
            text-align: center;

            svg {
                height: 2.5rem;
                width: 2.5rem;
                color: $text-grey
            }
        }

        li {
            margin-bottom: 2rem;
            cursor: pointer;
            font-size: 1.2rem;
            transition: 0.3s;

            &:hover {
                color: $primary-color;
            }
        }

        @media screen and (max-width: $lg-screen) {
            display: flex;
            width: 100%;
            min-height: unset;
            padding: 2rem;
            align-items: center;

            ul {
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: 2rem;

                li {
                    margin-bottom: 0;
                    white-space: nowrap
                }
            }

            .icon {
                margin: 0;
                margin-right: 1rem;
            }
        }
    }

    .settings-display {
        background-color: $bg-medium-transparent;
        padding: 2rem 1rem;
        border-radius: 5px;
        min-width: 40vw;

        .loading{
            margin-top: 2rem;
            font-size: 1.2rem;
        }


        h2 {
            font-size: 1.4rem;
            font-family: "Goldplay Medium";
        }

        hr {
            border: 0;
            border-top: 1px solid rgb(91, 91, 91);
            margin: 1.2rem auto 0;
        }

        .header-with-cta {
            flex-wrap: nowrap;
        }


        .list-item {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 1.2rem 0;
            border-bottom: 1px solid rgb(58, 58, 58);


            >div {
                display: flex;
            }

            .btn {
                padding: 0.5rem 1rem;
                font-size: 1rem;

                &.btn-delete {
                    margin-left: 1rem;
                }
            }

            .company-info {
                margin-right: 2rem;
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
        }
    }

    .priority-label {
        border-radius: 30px;
        padding: 0.2rem 0.6rem;
        text-transform: uppercase;
        font-family: 'Goldplay Semibold';
        font-size: 1rem;
        margin-right: 5px;
    }

    .status-color{
        display: inline-block;
        width: 3rem;
        margin-left: 1rem;
        border: 1px solid rgb(125, 125, 125);
    }

    .edit-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(58, 58, 58);
        margin-top: 1rem;
        padding-bottom: 1rem;

        .edit-form {
            display: flex;

            .company-input {
                margin-right: 2rem;

                input[type=text] {
                    padding: 0.4rem;
                    font-size: 1rem;
                    margin: 0;
                    min-width: 10rem
                }
            }

            .checkbox {
                display: flex;
                align-items: center;

                label {
                    display: inline-block;
                    width: 10rem;
                }

                input {
                    width: 1rem;
                    margin: 0;
                    display: inline-block;
                }
            }

            input[type=color] {
                width: 5rem;
                height: 2.5rem;
                width: 6rem;
                padding: 0;
                border-radius: 0;
                background: none;
            }

            
        }

        .btn-container {
            align-items: center;
            flex-wrap: nowrap;

            .btn {
                padding: 0.5rem 1rem;
                font-size: 1rem;

                &.btn-delete {
                    margin-left: 1rem;
                }
            }
        }
    }


}