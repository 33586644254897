@import './../../../assets/styles/app.scss';

.form-add--ticket {
    .first-row {
        display: flex;
        width: 100%;


        @media screen and (max-width: $sm-screen) {
            flex-direction: column;
        }

        .select-input {
            background: none;
            width: 100%;

            &:first-child {
                margin-right: 2rem;

                @media screen and (max-width: $sm-screen) {
                    margin-bottom: 1rem;
                }
            }

            display: flex;
            flex-direction: column;

            label {
                font-size: 1.2rem;
                margin-bottom: 1rem;
                position: relative;
                margin-top: 1rem;

                svg {
                    font-family: 'Material Icons';
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    right: 1.5rem;
                    bottom: -4rem;
                    pointer-events: none;
                }
            }

            select {
                -moz-appearance: none;
                /* Firefox */
                -webkit-appearance: none;
                /* Safari and Chrome */
                appearance: none;
                background: $bg-medium-transparent;
                color: $text-light;
                padding: 1.5rem;
                border: none;
                font-size: 1.1rem;
                outline: none;
                border-radius: 15px;
                cursor: pointer;
                text-transform: capitalize;
                margin-left: 0;
            }

            .alert {
                margin-top: 1rem;
            }
        }
    }

    .second-row {
        margin-top: 2rem;

        .form-input {
            display: flex;
            flex-direction: column;

            label {
                font-size: 1.2rem;
                margin-bottom: 1rem;
                position: relative;
                transform: none;
                left: 0;
                top: 0;
                font-size: 1.2rem;
                font-family: "Goldplay";
                cursor: pointer;

                @media screen and (max-width: $sm-screen) {
                    display: inline-block !important;
                }
            }

            input {
                background-color: $bg-medium-transparent;
                color: $text-light;
                padding: 1.5rem;
                border: none;
                border-radius: 15px;
                outline: none;
                font-size: 1.1rem;
                margin-bottom: 0;
            }
        }
    }

    .text-input {
        margin-top: 2rem;
        margin-bottom: 1rem;

        p {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        label {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            display: inline-block;
        }

        textarea {
            width: 100%;
            background-color: $bg-medium-transparent;
            color: $text-light;
            min-height: 8rem;
            border: none;
            border-radius: 15px;
            font-size: 1.1rem;
            padding: 1rem;
            outline: none;
        }
    }


    #ticket_echanges {
        input {
            margin-top: 5px;
        }
    }

    .create-btn {
        margin-top: 2rem;
    }
}

.upload-info {
    margin-top: 1rem;
    line-height: 1.3;
    color: rgb(177, 177, 177);
}