
@import './../assets/styles/app.scss';

.sidebar-header{
    background-color: #171717;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2rem;
    width: 17rem;
    position: fixed;
    z-index: 20;
    min-height: 100vh;

  
    @media screen and (max-width: $md-screen){
        left: -17rem;
        transition: 0.5s;
    }
  
    .sidebar-header--top{
        position: relative;

        .menu-toggle{
            position: absolute;
            width: 2rem;
            height: 2rem;
            top: 70%;
            right: -4rem;
            cursor: pointer;
            display: none;
            transform: rotate(90deg);
        
            @media screen and (max-width: $md-screen){
                display: inline-block;
            }
          
            svg{
               height: 2rem;
               width: 2rem;
            }
        }

    }
    .material-icons{
        margin-right: 8px;
    }

    .logo-container {
        img{ 
            object-fit: cover;
            height: 4rem;
        }
    }

    .nav-container{
        margin-top: 14vh;
        margin-bottom: 2rem;

        @media screen and (max-width: $md-screen){
          margin-top: 5vh;
        }

        h2{
            text-transform: uppercase;
            font-size: 1.4rem;
            margin-top: 3rem;
            margin-bottom: 1rem;
            @media screen and (max-width: $md-screen){
                font-size: 1.2rem;
            }
        }

        li{
            margin-top: 0.8rem;
            color: $text-grey;
            transition: 0.2s;

            a{
                display: flex;
                align-items: center;
                color: $text-grey;
                font-size: 1.2rem;
                transition: 0.2s;
                position: relative;

                @media screen and (max-width: $md-screen){
                    font-size: 1.1rem;
                }
            }

            &.to-handle{
                position: relative;

                &::after{
                    position: absolute;
                    content:"";
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: $primary-color ;
                    left: -10px;
                    top: -6px;
                }
            }

            
            &:hover{
                color: $text-light;

                a{
                color: $text-light;
                }
            }
        }

      
        .active{
            color: $text-light;
            a{
                color: $text-light;
            }
        }
    }

    .btn-logout{
            display: flex;
            align-items: center;
        }

}

.menu-open{
    left: 0;
}

.app-low-opacity{
    opacity: 0.1;
}