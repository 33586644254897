.pagination {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        
        li{
            margin: 0 0.5rem;
            cursor: pointer;

            &.disabled {
                cursor: default;
            }
        }
        .selected{
            background-color: #2eb5b2;
            border-radius: 50%;
            padding: 6px 12px;
            display: inline-block;
        }

        .previous, .next {
            font-size: 1.5rem;
        }
    }
}