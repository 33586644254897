.current-avatar{
    display: flex;
    align-items: center;
    background-color: $bg-medium-transparent;
    border-radius: 15px;
    padding: 0.7rem 1rem;
    margin-bottom: 3rem;
    border: solid 2px transparent;
    cursor: pointer;
    transition: 0.3s;

    &:hover{
            background-color: rgb(56, 56, 56);
    }


    p{
        font-size: 1.2rem;
        font-family: "Goldplay Semibold";
    }

    .avatar-img{
        width: 3rem;
        margin-left: 9rem;

        @media screen and (max-width: $sm-screen){
            margin-left: 6rem;
         }

        img{
            width: 100%;
            object-fit: cover;
        }
    }
}


.avatar-modal{
    position: absolute;
    width: 75%;
    z-index: 50;
    background-color: rgb(83, 83, 83);
    border-radius: $border-small;
    top: 40%;
    left: 20%;
    transform: translateY(-50%);
    padding: 1rem 1.5rem;
    max-width: 80rem;

    @media screen and (max-width: $md-screen){
        top: 35%;
        width: 85%;
        left: 10%;
    }

    .close-modal{
        text-align: right;
        margin-right: 2rem;
        margin-top: 1rem;
        font-size: 2rem;
        font-weight: 800;
        cursor: pointer;
    }

    .close-modal2{
        font-size: 1.2rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        margin-top: 2rem;
    }
    
    .avatars-container{
        height: 100%;
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        scrollbar-color: $primary-color $bg-medium-transparent;

        &::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: $bg-medium-transparent; 
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: $primary-color;
            border-radius: 8px;
        }

        @media screen and (max-width: $md-screen){
            max-height: 20rem ;
            overflow-y: auto;
            overflow-x: hidden;
        }    

        .avatar-img{
            cursor: pointer;
            position: relative;
            width: 8rem;
            height: 8rem;
            @media screen and (max-width: $md-screen){
                width: 6rem;
                height: 6rem;
            }                
            img{
                width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 6px solid transparent;
            }       
        }

        .selected{
            img{
                border: 6px solid $primary-color;
            }
        }
    }

    .btn{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}