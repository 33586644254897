input {
    width: 100%;
    display: block;
    color: $text-light;
    border: none;
    border-radius: 15px;
    background-color: $bg-medium-transparent;
    padding: 1.2rem 1rem;
    font-size: 1.1rem;
    font-family: "Goldplay";
    border: solid 2px transparent;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 1rem;

    &:focus {
        outline: none;
        border: solid 2px #444444;
    }

    &:hover {
        background-color: rgb(56, 56, 56);

    }
}

.password-input {
    position: relative;
    max-width: 30rem;

    input {
        padding: 1.2rem 4rem 1.2rem 1rem;
    }

    .show-icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);

        svg {
            color: $text-grey ;
            opacity: 0.4;
            width: 2rem;
            height: 2rem;
        }
    }
}

.form-input {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;

    label {
        transform: translateY(-50%);
        position: absolute;
        left: 1rem;
        top: 50%;
        font-size: 1.2rem;
        font-family: 'Goldplay Semibold';
        cursor: pointer;

        @media screen and (max-width: $sm-screen) {
            display: none;
        }

    }

    input {
        padding-left: 14rem;

        @media screen and (max-width: $sm-screen) {
            padding-left: 1rem;
        }
    }
}

.select-container {
    width: fit-content;
    background: $bg-medium-transparent;
    border-radius: 15px;
    margin-bottom: 1rem;
    position: relative;

    select {
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        margin-left: 13rem;
        background: $bg-medium-transparent;
        min-width: 12rem;
        color: white;
        padding: 1.2rem 1rem;
        border: none;
        font-size: 1.1rem;
        outline: none;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        cursor: pointer;
        text-transform: capitalize;

        @media screen and (max-width: $sm-screen) {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            margin-left: 0;
        }
    }
}

.add-file, .edit-file-cta, .cancel-edit-file {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    color: $primary-color;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 1rem;
}

.cancel-edit-file{
    color: $alert;

}

.upload-info {
    margin-top: 1rem;
    line-height: 1.3;
    color: rgb(177, 177, 177);
    margin-bottom: 1rem;
}

.uploads-container {
    input {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        transition: none;
        font-size: 1rem;
    }
}

.select-input{
    width: 100%;
    background: $bg-medium-transparent;
    border-radius: 15px;
  

   select{
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        margin-left: 13rem;
        background: $bg-medium-transparent;
        width: 100%;
        color: white;
        padding: 1rem;
        border: none;
        font-size: 1.1rem;
        outline: none;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        cursor: pointer;
        text-transform: capitalize;

        @media screen and (max-width: $sm-screen){
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            margin-left: 0;
        }
    }
}


input[type="color"]{
    max-width: 35rem;
    height: 5rem;
}