@import './../../assets/styles/app.scss';
@import './../../assets/styles/partials/avatar';
@import './../../assets/styles/partials/forms';

.profile-container{
    margin: auto;
    width: fit-content;
    text-align: center;

    h1{
        font-size: 1.8rem;
        font-family: "Goldplay Medium";
        text-transform: uppercase;
        margin-bottom: 1.5rem;

    }

    .profile-card{
        background-color: $bg-medium-transparent ;
        padding: 1.5rem 2rem 1.8rem;
        border-radius: $border-small;
        min-width: 14rem;
        width: max-content;
        min-width: 16rem;
        margin: auto;

        .avatar-container{
            img{
                width: 8rem;
                object-fit: cover;
            }
        }

        .name{
            text-transform: capitalize;
            font-family: "Goldplay SemiBold";
            font-size: 1.2rem;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        .email{
            font-size: 1.1rem;
            margin-top: 1.4rem;
            margin-bottom: 0.6rem;
        }

        .website, .phone{
            font-size: 1.1rem;
            margin-top: 1rem;
        }
    }
}

