@import './../../assets/styles/app.scss';


.login-container{
    background-color: $bg-dark;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .desktop-left, .loginForm-container{
        width: 100%;
        height: 100%;
        display: flex;
    }

    .desktop-left{
        background: linear-gradient(156deg, rgb(25, 25, 25)  0%, rgb(15, 15, 15) 44%, rgb(5, 5, 5) 100%);

        
        @media screen and (max-width: 56rem){
            display: none;
        }


        .desktop-left__iner{
            width: 80%;
            margin: auto;
            max-width: 35rem;
            position: relative;
            bottom: 5rem;
        }
        

        .logo-container{
            width: 5rem;
            margin-bottom: 3rem;
            img{
                width: 100%;
            }
        }
        .message-title{
            font-size:2rem;
            font-family: "Goldplay Medium";
            margin-bottom: 1rem;
        }

        .message-content{
            line-height: 1.4;
            font-size: 1.3rem;
            color: $text-grey;
        }

       
    }


    .loginForm-container{
        background: $bg-gradient-dark;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;



        .logo-container--mb{
            width: 5rem;
            margin-bottom: 3rem;
            display: none;
            img{
                width: 100%;
            }

            @media screen and (max-width: 56rem){
            margin-bottom: 4rem;
            display: block;
            }
        }

        form{
            width: 100%;
            max-width: 25rem;
            @media screen and (max-width: 56rem){
               max-width: 28rem;
            }
        }
        
        h1{
            font-size: 2rem;
            font-family: "Goldplay Medium";
            margin-bottom: 1.4rem;
        }
        .form-inputs{
            display:flex;
            flex-direction: column;

            .input-container{
                position: relative;
                
                .material-icons{
                    position: absolute;
                    top: 1rem;
                    left: 1.5rem;
                    color:#cacaca;

                    svg {
                        font-size: 2rem;
                    }
                }
                input{
                    background-color: $bg-medium-transparent;
                    color: white;
                    font-size: 1.2rem;
                    padding: 1.2rem 1rem 1.2rem 4.5rem;
                    border: none;
                    margin-bottom: 0.8rem;
                    outline: none;
                    border-radius: 10px;
                    width: 100%;

                    @media screen and (max-width: 56rem){
                        margin-bottom: 1.2rem;
                    }
                }

             }
        }

        .btn-login{
            margin-top: 1.5rem;
            cursor: pointer;

            @media screen and (max-width: 56rem){
                margin-top: 2.5rem;
            }
        }
    }
    
}